import { format, getTime, formatDistanceToNow } from 'date-fns';
import moment from 'moment-jalaali';

// ----------------------------------------------------------------------

export function fDateJalaali(date: Date | string | number) {
  return moment(new Date(date)).format('jYYYY/jMM/jDD');
}

export function fDateTimeJalaali(date: Date | string | number) {
  return moment(new Date(date)).format('jYYYY/jMM/jDD HH:mm');
}

export function fTime(date: Date | string | number) {
  return moment(new Date(date)).format('HH:mm');
}

export function fDateTimeShort(date: Date | string | number) {
  return format(new Date(date), 'yyyy/MM/dd k:m');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}
