import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axiosInstance from '../../utils/axiosConfig';

import { CheckoutData } from '../../@types/checkout';

// ----------------------------------------------------------------------

type CheckoutState = {
  isLoading: boolean;
  error: boolean;
  totalAmount: number;
  feePercent: number;
  checkoutList: CheckoutData[];
};

const initialState: CheckoutState = {
  isLoading: false,
  error: false,
  checkoutList: [],
  totalAmount: 0,
  feePercent: 0
};

const slice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    onCheckoutRequest(state, action) {
      const { _id, create_date, amount, fee, feePercent } = action.payload;

      const newCheckout: CheckoutData = {
        _id,
        create_date,
        amount,
        fee,
        feePercent
      };
      state.checkoutList.push(newCheckout);
    },

    // GET MANAGE Checkout
    getCheckoutListSuccess(state, action) {
      state.isLoading = false;
      state.checkoutList = action.payload.checkouts;
      state.totalAmount = action.payload.totalAmount;
      state.feePercent = action.payload.feePercent;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onCheckoutRequest } = slice.actions;

// ----------------------------------------------------------------------

export function getCheckoutList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get('checkout');
      dispatch(slice.actions.getCheckoutListSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function submitCheckout(amount: number) {
  return async () => {
    try {
      const response = await axiosInstance.post('checkout', {
        amount
      });
      if (response.status === 200) {
        const { _id, create_date, amount, fee, feePercent } = response.data.checkout;
        dispatch(
          slice.actions.onCheckoutRequest({
            _id,
            create_date,
            amount,
            feePercent,
            fee
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
}
