// material
import { styled } from '@mui/material/styles';
import { Toolbar } from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 11,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

// ----------------------------------------------------------------------

export default function UserListToolbar() {
  return <RootStyle></RootStyle>;
}
