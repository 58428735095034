import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axiosInstance from '../../utils/axiosConfig';

import { UserManager } from '../../@types/user';
import { TransactionManager } from '../../@types/transaction';

// ----------------------------------------------------------------------

type UserState = {
  isLoading: boolean;
  error: boolean;
  userList: UserManager[];
  userTransactionList: TransactionManager[];
};

const initialState: UserState = {
  isLoading: false,
  error: false,
  userList: [],
  userTransactionList: []
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    onToggleBlock(state, action) {
      const { userId, isBlocked } = action.payload;

      const handleToggle = map(state.userList, (user) => {
        if (user._id === userId) {
          return {
            ...user,
            isBlocked
          };
        }
        return user;
      });

      state.userList = handleToggle;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    // GET MANAGE USERS
    getUserTransactionsSuccess(state, action) {
      state.isLoading = false;
      state.userTransactionList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleBlock } = slice.actions;

// ----------------------------------------------------------------------

export function getUserList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get('user');
      dispatch(slice.actions.getUserListSuccess(response.data.users));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function userBlockChange(userId: string, isBlocked: boolean) {
  return async () => {
    try {
      dispatch(
        slice.actions.onToggleBlock({
          userId,
          isBlocked
        })
      );
      await axiosInstance.post('user/changeBlock', {
        isBlocked,
        userId
      });
    } catch (error) {
      dispatch(
        slice.actions.onToggleBlock({
          userId,
          isBlocked: !isBlocked
        })
      );
      console.log(error);
    }
  };
}

// ----------------------------------------------------------------------

export function getUserTransaction(phone: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get(`transaction/user/${phone}`);
      dispatch(slice.actions.getUserTransactionsSuccess(response.data.transactions));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
