import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axiosInstance from '../../utils/axiosConfig';

import { TransactionManager } from '../../@types/transaction';

// ----------------------------------------------------------------------

type TransactionState = {
  isLoading: boolean;
  error: boolean;
  transactionList: TransactionManager[];
  firstDate?: Date;
};

const initialState: TransactionState = {
  isLoading: false,
  error: false,
  transactionList: []
};

const slice = createSlice({
  name: 'transaction',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getTransactionSuccess(state, action) {
      state.isLoading = false;
      state.transactionList = action.payload;
    },

    getFirstDateSuccess(state, action) {
      state.isLoading = false;
      state.firstDate = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTransactionList(from: Date, to: Date) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get(`transaction/byDate/?from=${from}&to=${to}`);
      dispatch(slice.actions.getTransactionSuccess(response.data.transactions));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFirstDate() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get('transaction/minimumDate');
      dispatch(slice.actions.getFirstDateSuccess(response.data.date));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
