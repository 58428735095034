import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axiosInstance from '../../utils/axiosConfig';

import { DeviceManager } from '../../@types/device';

// ----------------------------------------------------------------------

type DeviceState = {
  isLoading: boolean;
  error: boolean;
  deviceList: DeviceManager[];
};

const initialState: DeviceState = {
  isLoading: false,
  error: false,
  deviceList: []
};

const slice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Devices
    getDeviceListSuccess(state, action) {
      state.isLoading = false;
      state.deviceList = action.payload;
    },

    onDeviceAddUpdate(state, action) {
      const { _id, create_date, brand, model, version, deviceStatus } = action.payload;

      if (deviceStatus === 'edit') {
        state.deviceList = map(state.deviceList, (device) => {
          if (device._id === _id) {
            return {
              ...device,
              brand,
              model,
              version
            };
          }
          return device;
        });
      } else {
        const newDevice: DeviceManager = {
          _id,
          create_date,
          brand,
          model,
          version
        };
        state.deviceList.push(newDevice);
      }
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onDeviceAddUpdate } = slice.actions;

// ----------------------------------------------------------------------

export function getDeviceList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get('device');
      dispatch(slice.actions.getDeviceListSuccess(response.data.devices));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

type AddUpdateType = {
  _id: string;
  model: string;
  brand: string;
  version: string;
};

export function deviceAddUpdate(data: Partial<AddUpdateType>) {
  return async () => {
    try {
      let deviceStatus = 'new';
      if (data?._id) {
        deviceStatus = 'edit';
      }
      const response = await axiosInstance.post('device', data);
      if (response.status === 200) {
        const responseData = response.data.device;
        dispatch(
          slice.actions.onDeviceAddUpdate({
            ...responseData,
            deviceStatus
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
}
