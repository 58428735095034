import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import transactionReducer from './slices/transaction';
import deviceReducer from './slices/device';
import checkoutReducer from './slices/checkout';
import proxyReducer from './slices/proxy';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

// const productPersistConfig = {
//   key: 'product',
//   storage,
//   keyPrefix: 'redux-',
//   whitelist: ['sortBy', 'checkout']
// };

const rootReducer = combineReducers({
  user: userReducer,
  transaction: transactionReducer,
  device: deviceReducer,
  checkout: checkoutReducer,
  proxy: proxyReducer
});

export { rootPersistConfig, rootReducer };
