import { map } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axiosInstance from '../../utils/axiosConfig';

import { ProxyManager } from '../../@types/proxy';

// ----------------------------------------------------------------------

type ProxyState = {
  isLoading: boolean;
  error: boolean;
  proxyList: ProxyManager[];
};

const initialState: ProxyState = {
  isLoading: false,
  error: false,
  proxyList: []
};

const slice = createSlice({
  name: 'proxy',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    onProxyAddUpdate(state, action) {
      const {
        _id,
        user,
        ip,
        port,
        type,
        create_date,
        pass,
        order,
        successCounter,
        callCounter,
        proxyStatus
      } = action.payload;

      if (proxyStatus === 'edit') {
        const handleToggle = map(state.proxyList, (proxy) => {
          if (proxy._id === _id) {
            return {
              ...proxy,
              user,
              ip,
              port
            };
          }
          return proxy;
        });

        state.proxyList = handleToggle;
      } else {
        const newProxy: ProxyManager = {
          _id,
          user,
          ip,
          port,
          type,
          create_date,
          pass,
          order,
          successCounter,
          callCounter
        };
        state.proxyList.push(newProxy);
      }
    },

    onProxyDelete(state, action) {
      const { _id } = action.payload;
      const handleToggle = map(state.proxyList, (proxy) => {
        if (proxy._id !== _id) {
          return {
            ...proxy
          };
        }
        return proxy;
      });

      state.proxyList = handleToggle;
    },

    // GET Proxies
    getProxyListSuccess(state, action) {
      state.isLoading = false;
      state.proxyList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onProxyAddUpdate, onProxyDelete } = slice.actions;

// ----------------------------------------------------------------------

export function getProxyList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.get('proxy');
      dispatch(slice.actions.getProxyListSuccess(response.data.proxies));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
type AddUpdateType = {
  _id: string;
  user: string;
  pass: string;
  ip: string;
  port: string;
  type: string;
};

export function proxyAddUpdate(data: Partial<AddUpdateType>) {
  return async () => {
    try {
      let proxyStatus = 'new';
      if (data?._id) {
        proxyStatus = 'edit';
      }
      const response = await axiosInstance.post('proxy', data);
      if (response.status === 200) {
        const responseData = response.data.proxy;
        dispatch(
          slice.actions.onProxyAddUpdate({
            ...responseData,
            proxyStatus
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
}

// ---------------------------

export function proxyDelete(_id: string) {
  return async () => {
    try {
      const response = await axiosInstance.delete(`proxy/${_id}`);
      if (response.status === 200) {
        dispatch(
          slice.actions.onProxyDelete({
            _id
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
}
